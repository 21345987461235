import React from "react";
import CSVToSRTConverter from "./CSVToSRTConverter";

const App = () => {
  return (
    <div className="App">
      <CSVToSRTConverter />
    </div>
  );
};

export default App;
